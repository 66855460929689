import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import CTA from '../components/cta'
import patternHex from '../images/backrounds/pattern-hex-white.jpg'
import { StaticImage } from 'gatsby-plugin-image'
import Quote from '../components/quote'
import { Card } from 'react-bootstrap'
import ExamplePartOne from '../docs/Zendesk-Evaluation-Platinum-Part-1.pdf'
import ExamplePartTwo from '../docs/Zendesk-Evaluation-Platinum-Part-2.pdf'

const recentMigrations = [
  <StaticImage src='../images/clients/zendesk-customers/genomix.png' className='m-auto' alt='Genomix' placeholder='blurred' />,
  <StaticImage src='../images/clients/zendesk-customers/accureg.png' className='m-auto' alt='Accureg' placeholder='blurred' />,
  <StaticImage src='../images/clients/zendesk-customers/bradley.png' className='m-auto' alt='Bradley' placeholder='blurred' />,
  <StaticImage src='../images/clients/zendesk-customers/kidkraft.png' className='m-auto' alt='Kidkraft' placeholder='blurred' />,
  <StaticImage src='../images/clients/zendesk-customers/learnondemand.png' className='m-auto' alt='Learnondemand' placeholder='blurred' />,
  <StaticImage src='../images/clients/zendesk-customers/singularity.png' className='m-auto' alt='Singularity' placeholder='blurred' />,
]

const Index = ({ location }) => (
  <Layout>
    <Seo
      pathName={location.pathname}
      title='Zendesk Efficiency Audits'
      description='Leverage our 4 years of expertise to evaluate your Zendesk implementation in one of our Zendesk Efficiency Audits.'
    />
    <section>
      <div
        className='hero'
        style={{
          backgroundImage: `url(${patternHex})`,
        }}
      >
        <div className='hero-title bg-dark-transparent'>
          <div className='hero-image'>
            <StaticImage src='../images/clients/zendesk.png' alt='Zendesk Implementation Premier Partner' layout='constrained' style={{ maxWidth: '200px' }} />
          </div>
          <h1 className='text-white'>zendesk efficiency audits</h1>
          <p className='text-white'>Leverage our 4 years of expertise to evaluate your Zendesk implementation.</p>
          <CTA href='/contact-us/' className='btn btn-primary' text='Request an audit now' />
        </div>
      </div>
    </section>
    <section>
      <div className='container py-6'>
        <div className='row'>
          <div className='col-lg-6'>
            <h2>why invest in an efficiency audit?</h2>
            <ul className='text-primary m-0 pl-3'>
              <li>
                <span className='text-dark'>Find workflow challenges for agents and managers</span>
              </li>
              <li>
                <span className='text-dark'>Expose customer experience problems and solutions</span>
              </li>
              <li>
                <span className='text-dark'>Discover areas to optimize</span>
              </li>
              <li>
                <span className='text-dark'>Bring best practices to your admins and agents</span>
              </li>
              <li>
                <span className='text-dark'>Leverage the experience of a veteran Zendesk development partner</span>
              </li>
              <li>
                <span className='text-dark'>ROI – Save time for your agents on each ticket!</span>
              </li>
            </ul>
          </div>
          <div className='col-lg-6'>
            <h2>how does it work?</h2>
            <ul className='text-primary m-0 pl-3'>
              <li>
                <span className='text-dark'>
                  <strong>Start quickly:</strong>&nbsp;After deciding which plan is right for you,{' '}
                  <a href='https://729solutions.com/contact-us/'>reach out to us</a> and we’ll schedule a kick off call. The whole audit normally takes 2 weeks
                  to complete.
                </span>
              </li>
              <li>
                <span className='text-dark'>
                  <strong>Quality work</strong>: Our certified Zendesk admins have deep competency in looking at Zendesk instances and quickly identifying areas
                  for improvement.
                </span>
              </li>
              <li>
                <span className='text-dark'>
                  <strong>Best practices</strong>: We have worked with a wide range of customer types and verticals so we can bring experience and perspective
                  on what leading brands are doing to succeed.
                </span>
              </li>
              <li>
                <span className='text-dark'>
                  <strong>A Valuable Report</strong>: Our detailed reports will give you practical tips and precise direction to take to improve the way your
                  agents work and provide a better customer experience.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className='bg-light py-6'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6'>
            <Card className='card custom-cards bg-transparent' style={{ boxShadow: 'none' }}>
              <Card.Body>
                <div className='text-left'>
                  <h2 className='mb-1'>check the basics of your zendesk efficiency yourself</h2>
                  <p>Download our Zendesk Efficiency Checklist to ensure you’re covering the basics of Zendesk Efficiency.</p>
                  <div className='mt-5'>
                    <CTA className='btn btn-primary' text='Get Our Efficiency Checklist' href='/lp/zendesk-efficiency-checklist' />
                  </div>
                </div>
                <div className='text-left mt-5'>
                  <h2 className='mb-1'>then let us take your instance to the next level.</h2>
                  <p>Schedule a consultation with one of our experienced Zendesk experts and let us do a deep dive for you.</p>
                  <div className='text-left mt-5'>
                    <a className='btn btn-primary mb-5' href={ExamplePartOne}>
                      View Example, Part 1
                    </a>
                    <a className='btn btn-primary' href={ExamplePartTwo}>
                      View Example, Part 2
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className='col-lg-6 d-flex'>
            <Card className='card custom-cards card-border' style={{ boxShadow: 'none' }}>
              <Card.Body>
                <h2>zendesk efficiency audit details</h2>
                <ul className='text-primary text-left m-0 pl-3'>
                  <li>
                    <span className='text-dark'>Up to 40 hours of review time</span>
                  </li>
                  <li>
                    <span className='text-dark'>One 7-15 page report depending on complexity of instance</span>
                  </li>
                  <li>
                    <span className='text-dark'>One 30 minute phone consultation before the review is done to discuss areas of interest, challenges, etc.</span>
                  </li>
                  <li>
                    <span className='text-dark'>
                      One 60 minute phone consultation after the report is complete to review the results and discuss solutions $250 discount on any work done
                      to make improvements and changes
                    </span>
                  </li>
                </ul>
                <StaticImage src='../icons/evaluating.svg' alt='Evaluating' className='mt-4' />
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </section>

    <section className='py-6'>
      <div className='container'>
        <div className='row'>
          <div className='col'>
            <div className={'text-center p-6 bg-dark'}>
              <h2 className={'text-white'}>ready for us to complete a zendesk efficiency audit for your company?</h2>
              <CTA href='/contact-us/' text='Tell us about your project' className={'btn btn-primary'} />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className='container pb-6'>
        <div className='row'>
          <div className='col'>
            <h2 className='text-center'>recent zendesk customers</h2>
          </div>
        </div>
        <div className='row'>
          {recentMigrations.map((component) => (
            <div className='col-12 col-lg-4'>
              <div className='row'>
                <div className='col-12 col-lg-8'>
                  <div className='row'>
                    <div className='col-4 col-lg-12 ml-auto mr-auto mb-5'>{component}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
    <section>
      <div className='container'>
        <div className='row'>
          <div className='col'>
            <Quote name='Daniel Davis' company='Namecheap' quote='The structured reporting was great. It really gave us what we needed.' />
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Index
